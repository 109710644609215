import React from "react";

import Carousel from "components/botanic/Carousel";

import MenuCardSkeleton from "./MenuCardSkeleton";

const SkeletonCarousel = ({ header }: { header: string }) => {
  return (
    <>
      <h2 data-testid="section-header">{header}</h2>
      <Carousel seeMoreText={`more ${header.toLowerCase()}`}>
        {[1, 2, 3, 4].map((_, i) => {
          return (
            <div
              key={i}
              className="pb-sm"
              role="button"
              tabIndex={0}
              data-testid="carousel-skeleton-item"
            >
              <MenuCardSkeleton className="bg-white" />
            </div>
          );
        })}
      </Carousel>
    </>
  );
};

export default SkeletonCarousel;
