import React, { useId } from "react";
import ContentLoader from "react-content-loader";

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
const MenuCardSkeleton = (props: any) => {
  const uniqueKey = useId();
  return (
    <ContentLoader
      speed={2}
      width={239}
      height={350}
      viewBox="0 0 239 350"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      uniqueKey={uniqueKey}
      {...props}
    >
      <rect x="63.5" y="12" rx="0" ry="0" width="112" height="112" />

      <rect x="16" y="152" rx="3" ry="3" width="207" height="28" />
      <rect x="16" y="182" rx="3" ry="3" width="164" height="28" />
      <rect x="16" y="212" rx="3" ry="3" width="112" height="18" />
      <rect x="16" y="236" rx="3" ry="3" width="112" height="16" />

      <rect x="16" y="258" rx="3" ry="3" width="10" height="20" />
      <rect x="28" y="258" rx="3" ry="3" width="50" height="40" />
      <rect x="80" y="258" rx="3" ry="3" width="30" height="20" />
      <rect x="16" y="306" rx="3" ry="3" width="207" height="28" />
    </ContentLoader>
  );
};

export default MenuCardSkeleton;
